<template>
  <auth-form-wrapper
    :title="$t('auth.forgot-password.title')"
    :subtitle="$t('auth.forgot-password.subtitle')"
    :error="error"
  >
    <div class="auth-form-wrapper-content" 
      v-if="!sent"
    >
      <b-form class="auth-form mt-2" @submit.prevent="handleSubmit">
        <!-- Email -->
        <div class="mb-1">
          <label class="form-label">
            {{ $t('auth.forgot-password.form.email.label') }}
          </label>
          <b-form-input
            id="forgot-email"
            v-model="formData.email"
            type="email"
            name="email"
            :placeholder="$t('auth.forgot-password.form.email.placeholder')"
            required
          />
        </div>

        <!-- Submit Button -->
        <div class="auth-btn-container d-flex justify-content-center">
          <b-button
            type="submit"
            variant="primary"
            class="auth-btn auth-btn-primary mt-2 mb-2"
          >
            {{ $t('auth.forgot-password.form.action.submit') }}
          </b-button>
        </div>
      </b-form>
    </div>

    <!-- Success Message -->
    <b-alert
      v-if="sent"
      show
      variant="success"
    >
      <div class="alert-body text-center">
        <h3 class="mb-1">{{ $t('auth.forgot-password.success.title') }}</h3>
        <p>{{ $t('auth.forgot-password.success.message') }}</p>
        <p>{{ $t('common.email-attention') }}</p>
        <p>{{ $t('auth.forgot-password.success.support', { email: supportEmail }) }}</p>
      </div>
    </b-alert>

    <!-- Back to Login Link -->
    <p class="text-center mt-2">
      <b-link class="auth-link" :to="{ name: 'auth-login' }">
        {{ $t('auth.forgot-password.back-to-login') }}
      </b-link>
    </p>
  </auth-form-wrapper>
</template>

<script>
import AuthFormWrapper from '@/auth/components/AuthFormWrapper.vue'

export default {
  name: 'ForgotPassword',

  components: {
    AuthFormWrapper
  },

  inject: ['authApiUrl'],

  data() {
    return {
      formData: {
        email: ''
      },
      error: null,
      sent: false
    }
  },

  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.collective;
    },
    communitySlug() {
      return this.$route.params.communityId
    },
    isIntercontinentalAlliance() {
      return this.mainSpace?.key === '6725a490-2547-11ee-a8ab-dfc8db3ddec2';
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.mainSpace?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
    supportEmail() {
      if(this.isNexos){
        return process.env.VUE_APP_SUPPORT_EMAIL || 'comunidad@nexos.infobancolombia.com';
      }
      if(this.isIntercontinentalAlliance){
        return 'support@intercontinentalalliance.com';
      }
      return process.env.VUE_APP_SUPPORT_EMAIL || 'support@nectios.com';
    }
  },

  methods: {
    async handleSubmit() {
      // reset error
      this.error = null;

      const endpoint = this.authApiUrl + 'password';

      try {
        const response = await fetch(
          endpoint,
          {
            method: 'POST',
            body: JSON.stringify({
              email: this.formData.email,
              locale: this.$store.state.locale.currentLocale,
              communitySlug: this.communitySlug
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        if (response.ok) {
          this.sent = true

          // Bypass for testing environments
          if (
            window.location.href.includes('nectios-staging.com/') ||
            window.location.href.includes('localhost')
          ) {
            console.log('Bypass reset password email for testing')
            const { token } = await response.json()
            
            this.$router.push({
              name: 'auth-reset',
              params: { 
                communityId: this.communitySlug,
                token 
              }
            })
          }
        } else {
          console.log('Something went wrong...')
          this.error = error.message || this.$t('auth.forgot-password.error')
        }
      } catch (error) {
        console.error('Error:', error)
        this.error = error.message || this.$t('auth.forgot-password.error')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-merge {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
